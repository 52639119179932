import React, { useState } from "react";
import CustomDatePickerV2 from "./CustomDatePickerV2";
import moment from "moment";
interface DateRangePicker {
  startDate: any;
  endDate: any;
}

interface DateSelectionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (dates: any) => any;
  bookingRequestDetails: any;
}

const DateSelectionModal: React.FC<DateSelectionModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  bookingRequestDetails,
}) => {
  const propertyCheckIn =
    bookingRequestDetails?.booking_query?.property?.availability_start;
  const propertyCheckOut =
    bookingRequestDetails?.booking_query?.property?.availability_end;

  const [dates, setDates] = useState<DateRangePicker>({
    startDate: null,
    endDate: null,
  });

  const handleSubmit = () => {
    if (onSubmit) {
      const newDates = {
        startDate: moment(dates.startDate)?.format("YYYY-MM-DD"),
        endDate: moment(dates.endDate)?.format("YYYY-MM-DD"),
      };
      onSubmit(newDates);
      setDates({ ...dates, startDate: "", endDate: "" });
    }
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-opacity-50 dark:bg-black-800 dark:bg-opacity-75 z-40 backdrop-blur-md"
      onClick={onClose}
    >
      <div
        className="enquiry-modal bg-white dark:bg-gray-900 rounded-lg shadow-lg p-6 w-full max-w-md relative mt-16 z-50 mr-7 relative"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          onClick={onClose}
          className="absolute top-3 right-3 text-gray-600 hover:text-gray-800"
        >
          ✕
        </button>

        <h3 className="text-xl font-semibold text-gray-800 dark:text-white mb-2 text-center">
          Change Requested Dates
        </h3>

        <div className="grid gap-4">
          <div className="space-y-2">
            <label className="text-gray-700 dark:text-gray-300">Check In</label>
            <CustomDatePickerV2
              placeholder={`Check In`}
              value={dates.startDate ? moment(dates.startDate) : undefined}
              onChange={(value) => {
                setDates((prev) => ({
                  ...prev,
                  startDate: value,
                }));
              }}
              minDate={moment(
                moment(propertyCheckIn).isAfter(moment())
                  ? propertyCheckIn
                  : new Date()
              ).toDate()}
              maxDate={
                propertyCheckOut ? moment(propertyCheckOut).toDate() : undefined
              }
            />
          </div>

          <div className="space-y-2">
            <label className="text-gray-700 dark:text-gray-300">
              Check Out
            </label>
            <CustomDatePickerV2
              placeholder={`Check Out`}
              value={dates.endDate ? moment(dates.endDate) : undefined}
              onChange={(value) => {
                setDates((prev) => ({
                  ...prev,
                  endDate: value,
                }));
              }}
              minDate={moment(
                moment(propertyCheckIn).isAfter(moment())
                  ? propertyCheckIn
                  : new Date()
              ).toDate()}
              maxDate={
                propertyCheckOut ? moment(propertyCheckOut).toDate() : undefined
              }
            />
          </div>

          <button
            disabled={!dates.startDate || !dates.endDate}
            onClick={handleSubmit}
            className="w-full px-4 py-2 mt-4 text-white bg-red-400 rounded-md hover:bg-red-500 disabled:opacity-50"
          >
            Change Requested Dates
          </button>
        </div>
      </div>
    </div>
  );
};

export default DateSelectionModal;
