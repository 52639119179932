import React from "react";
import Skeleton from "react-loading-skeleton";

const BookingDetailsSkeleton = () => {
  return (
    <div className="max-w-7xl mx-auto p-4 lg:container">
      <Skeleton height={32} width={250} className="mb-6" />

      <div className="mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:col-span-2 mb-8">
          <div className="border border-gray-300 dark:bg-black p-4 md:p-8 rounded-lg mb-5">
            <div className="flex justify-between items-center">
              {[1, 2, 3, 4, 5].map((i) => (
                <div key={i} className="text-center">
                  <Skeleton circle width={24} height={24} className="mb-2" />
                  <Skeleton width={60} />
                </div>
              ))}
            </div>
          </div>

          <div className="bg-white dark:bg-black shadow-lg rounded-lg p-8 border border-gray-300">
            <Skeleton height={24} width="75%" className="mb-6" />

            {[1, 2, 3, 4].map((i) => (
              <div key={i} className="flex justify-between mb-4">
                <Skeleton width={100} />
                <Skeleton width={150} />
              </div>
            ))}

            <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg mt-6">
              <div className="flex gap-4">
                <Skeleton height={48} width={120} />
                <Skeleton height={48} width={120} />
              </div>
            </div>

            <div className="mt-6 p-6 bg-gray-100 dark:bg-black rounded-lg">
              <Skeleton width={60} className="mb-4" />
              <Skeleton height={120} className="mb-4" />
              <div className="flex justify-end gap-4">
                <Skeleton width={100} height={40} />
                <Skeleton width={100} height={40} />
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="bg-orange-500 rounded-lg p-6 w-full h-96">
            <div className="flex flex-col items-center">
              <Skeleton circle width={96} height={96} className="mb-4" />
              <Skeleton width={150} className="mb-4" />
              {[1, 2, 3].map((i) => (
                <Skeleton key={i} width="80%" className="mb-3" />
              ))}
            </div>
          </div>

          <div className="w-full mt-5 p-6 bg-white dark:bg-black rounded-lg">
            <Skeleton height={24} width="90%" className="mb-4" />
            <Skeleton height={200} className="mb-4" />
            {[1, 2, 3, 4, 5].map((i) => (
              <div key={i} className="flex justify-between mb-3">
                <Skeleton width={120} />
                <Skeleton width={80} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingDetailsSkeleton;
