import React from "react";
import Skeleton from "react-loading-skeleton";
import StayCardSkeleton from "./CardSkeltion";

const SectionGridFeaturePlacesSkeleton = () => {
  return (
    <div className="nc-SectionGridFeaturePlaces container relative relative p-6 rounded-xl">
      <div className="flex flex-col sm:flex-row sm:items-center justify-between mb-8">
        <div className="max-w-2xl">
          <Skeleton height={32} width={300} className="mb-4" />
          <Skeleton height={20} width={400} />
        </div>
      </div>

      <div className="grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3">
        {[1, 2, 3, 4, 5, 6].map((i) => (
          <StayCardSkeleton key={i} />
        ))}
      </div>

      <div className="flex mt-16 justify-center items-center space-x-4">
        <Skeleton width={120} height={45} />
        <Skeleton width={120} height={45} />
      </div>
    </div>
  );
};

export default SectionGridFeaturePlacesSkeleton;
