import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import API from "../../../utils/helpers/APIHelper";
import { RootState } from "../../store";
import ErrorHandler from "../../../utils/helpers/ErrorHandler";
import { ENDPOINTS } from "../../../API/apiConstants";
import { ChatListsResponse } from "./ChatSliceTypes";

type PaymentInitialState = {
  loading: boolean;
  chatLists: ChatListsResponse;
};

const initialState: PaymentInitialState = {
  loading: false,
  chatLists: {
    message: "",
    chats: [],
    meta: {
      current_page: null,
      next_page: null,
      prev_page: null,
      total_count: null,
      total_pages: null,
    },
    success: false,
  },
};

export const getChatLists = createAsyncThunk<
  any,
  number,
  { state: RootState; rejectValue: any }
>("chat/getChatLists", async (page, { getState, rejectWithValue }) => {
  const { currentUser } = getState().user; 

  try {
    const endpoint =
      currentUser?.is_student
        ? ENDPOINTS.STUDENT_CHAT_LISTS(page)
        : ENDPOINTS.HOST_CHAT_LISTS(page);

    const response = await API.get(endpoint);
    const data = response.data;
    return data;
  } catch (error) {
    // ErrorHandler(error);
    return error;
  }
});

export const getChatSearch = createAsyncThunk<
  any,
  string,
  { state: RootState; rejectValue: any }
>("chat/getChatSearch", async (query, { rejectWithValue }) => {
  try {
    const response = await API.get(ENDPOINTS.CHAT_SEARCH(query));
    const data = response.data;
    return data;
  } catch (error) {
    //ErrorHandler(error);
    return error;
  }
});

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    resetCount: (state, action) => {
      state.chatLists = {
        ...state.chatLists,
        chats: state.chatLists?.chats?.map((chat) =>
          chat.id === action?.payload ? { ...chat, unread_count: 0 } : chat
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(getChatLists.fulfilled, getChatSearch.fulfilled),
      (state, action) => {
        state.loading = false;
        state.chatLists = action.payload;
      }
    );
    builder.addMatcher(
      (action) => [getChatLists.pending.type].includes(action.type),
      (state) => {
        state.loading = true;
      }
    );
    builder.addMatcher(
      (action) => [getChatLists.rejected.type].includes(action.type),
      (state) => {
        state.loading = false;
      }
    );
  },
});

export const { resetCount } = chatSlice.actions;

export default chatSlice.reducer;
