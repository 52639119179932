import React from 'react';
import Skeleton from 'react-loading-skeleton';

const StayCardSkeleton = () => {
  return (
    <div className="nc-StayCard group bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden">
      <div className="relative w-full">
        <Skeleton height={240} className="aspect-w-4 aspect-h-3" />
      </div>
      
      <div className="p-4 space-y-4">
        <div className="space-y-2">
          <div className="flex items-center justify-between">
            <Skeleton width={80} />
            <Skeleton width={80} />
          </div>
          
          <div className="flex items-center space-x-2">
            <Skeleton width={200} height={24} />
          </div>
          
          <div className="flex items-center space-x-2">
            <Skeleton circle width={20} height={20} />
            <Skeleton width={150} />
          </div>
        </div>
        
        <div className="w-14 border-b border-neutral-100 dark:border-neutral-800" />
        
        <div className="flex justify-between items-center">
          <Skeleton width={120} height={24} />
        </div>
      </div>
    </div>
  );
};

export default StayCardSkeleton;