import React, { FC, useEffect, useState } from "react";
import LocationInput from "../LocationInput";
import GuestsInput from "../GuestsInput";
import StayDatesRangeInput from "./StayDatesRangeInput";

const StaySearchForm: FC<{}> = () => {
  const [userLocation, setUserLocation] = useState<{
    latitude: number;
    longitude: number;
    locationData: any
  } | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<any>(null);
  const [locationError, setLocationError] = useState<string>("");
  const [dateError, setDateError] = useState<string>("");
  const [guestsError, setGuestsError] = useState<string>("");
  const [guests, setGuests] = useState<number | null>(null);


  const resetSearchForm = () => {
    setUserLocation(null);
    setSelectedLocation(null);
    setStartDate(new Date());
    setEndDate(null);
    setGuests(null);
    setLocationError("");
    setDateError("");
    setGuestsError("");
  };


  const validateForm = () => {
    let isValid = true;

    if (!selectedLocation) {
      setLocationError("Please select a location.");
      isValid = false;
    } else {
      setLocationError("");
    }

    if (!startDate || !endDate) {
      setDateError("Please select end dates.");
      isValid = false;
    } else {
      setDateError("");
    }

    if (!guests || guests < 1) {
      setGuestsError("Please select at least one guest.");
      isValid = false;
    } else {
      setGuestsError("");
    }

    return isValid;
  };

  const renderForm = () => {
    return (
      <form className="w-full relative mt-8 flex rounded-full shadow-xl dark:shadow-2xl bg-white dark:bg-neutral-800 ">
        <LocationInput
          className="flex-[1.5]"
          setUserLocation={setUserLocation}
          setSelectedLocation={setSelectedLocation}
          error={locationError}
          setError={setLocationError}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <StayDatesRangeInput
          className="flex-1"
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          dateError={dateError}
          setDateError={setDateError}
        />
        <div className="self-center border-r border-slate-200 dark:border-slate-700 h-8"></div>
        <GuestsInput
          className="flex-[1.5]"
          userLocation={userLocation}
          selectedLocation={selectedLocation}
          startDate={startDate}
          endDate={endDate}
          validateLocation={validateForm}
          guestsError={guestsError}
          setGuests={setGuests}
          setGuestsError={setGuestsError}
          resetForm={resetSearchForm}
        />
      </form>
    );
  };

  return renderForm();
};

export default StaySearchForm;
