import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserBookingsDetails,
  getHostBookingsDetails,
} from "../../../redux/reducers/Properties/PropertiesSlice";
import { AppDispatch, RootState } from "redux/store";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import StayCardV4 from "components/StayCard/StayCardV4";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilterV2 from "containers/PageHome/HeaderFilterV2";
import SectionGridFeaturePlacesSkeleton from "shared/Skelton/SectionGirdSkelton";

// Types
interface BookingData {
  id: string;
  [key: string]: string | number | boolean;
}

const MyRequest: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(1);
  const [bookingData, setBookingData] = useState<BookingData[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [metaData, setMetaData] = useState<any>([]);
  const { currentUser } = useSelector((state: RootState) => state.user);

  const fetchBookings = useCallback(
    async (pageNumber: number) => {
      setIsLoading(true);
      try {
        if (currentUser?.is_host) {
          const response = await dispatch(getHostBookingsDetails(pageNumber));
          if (response?.payload?.bookings) {
            setBookingData(response.payload.bookings);
            setMetaData(response.payload);

            return true;
          }
        } else if (!currentUser?.is_host) {
          const response = await dispatch(
            getUserBookingsDetails(page)
          ).unwrap();
          setBookingData(response?.bookings);
          setMetaData(response.payload);
        }
      } catch (error) {
        console.error("Error fetching bookings:", error);
        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, currentUser?.is_host]
  );

  // Pagination handlers
  const handleLoadMore = useCallback(async () => {
    if (metaData?.meta?.next_page && !isLoading) {
      const nextPage = page + 1;
      const success = await fetchBookings(nextPage);
      if (success) {
        setPage(nextPage);
      }
    }
  }, [metaData?.meta?.next_page, page, isLoading, fetchBookings]);

  const handleGoBack = useCallback(async () => {
    if (metaData?.meta?.prev_page && page > 1 && !isLoading) {
      const prevPage = page - 1;
      const success = await fetchBookings(prevPage);
      if (success) {
        setPage(prevPage);
      }
    }
  }, [metaData?.meta?.prev_page, page, isLoading, fetchBookings]);

  useEffect(() => {
    fetchBookings(1);
  }, []);

  if (isLoading && !bookingData.length) {
    return (
      <div className="p-10">
        <SectionGridFeaturePlacesSkeleton />;
      </div>
    );
  }

  return (
    <div className="nc-PageHome relative overflow-hidden">
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <div className="relative py-16">
          <HeaderFilterV2
            heading="My Bookings"
            subHeading="View the complete list of all your booking requests."
          />
          <BackgroundSection />

          {/* Navigation buttons */}

          {/* Bookings grid */}
          {bookingData.length > 0 ? (
            <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3">
              {bookingData.map((data) => (
                <StayCardV4 key={data.id} booking={data} />
              ))}
            </div>
          ) : (
            <div className="text-center py-10">
              <h3 className="text-2xl font-semibold">No bookings found</h3>
              <p className="text-gray-500 mt-2">
                There are currently no bookings to display.
              </p>
            </div>
          )}

          <div className="flex mt-16 justify-center items-center space-x-4">
            {metaData?.meta?.total_pages > 1 && (
              <>
                <ButtonPrimary
                  onClick={handleGoBack}
                  disabled={!metaData?.meta?.prev_page || isLoading}
                  className="px-6"
                  loading={isLoading}
                >
                  Previous Page
                </ButtonPrimary>

                <ButtonPrimary
                  onClick={handleLoadMore}
                  disabled={!metaData?.meta?.next_page || isLoading}
                  className="px-6"
                  loading={isLoading}
                >
                  Next Page
                </ButtonPrimary>
              </>
            )}
          </div>

          {/* Loading indicator for pagination */}
          {/* {isLoading && (
            <div className="flex justify-center mt-6">
              <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-primary-500"></div>
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default MyRequest;
