import React, { useState } from "react";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";
import ButtonPrimary from "shared/Button/ButtonPrimary";

interface PropertyDescriptionProps {
 propertyData: PropertyToEdit | null;
}

const PropertyDescription: React.FC<PropertyDescriptionProps> = ({
   propertyData
}) => {
  const [showFullDescription, setShowFullDescription] = useState(false);

  if (!propertyData) return null;

    const toggleDescription = () => {
      setShowFullDescription(!showFullDescription);
    };
  const truncatedDescription =
      propertyData?.description?.length > 150
        ? propertyData.description.substring(0, 150) + "..."
        : propertyData?.description || "";

    if (!propertyData) return null;

  return (
      <div
        className={`listingSection__wrap ${
          propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
        }`}
      >
        <h2 className="text-2xl font-semibold">Property Description</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300 break-words">
          <span>
            {showFullDescription
              ? propertyData?.description
              : truncatedDescription}
          </span>
          <div className="mt-5">
            {propertyData?.description?.length > 150 && (
              <ButtonPrimary onClick={toggleDescription} className="">
                {showFullDescription ? "Show Less" : "Show More"}
              </ButtonPrimary>
            )}
          </div>
        </div>
      </div>
  );
};

export default PropertyDescription;
