import { Modal, Button } from "react-rainbow-components";

interface CommonModalProps {
  isOpen: boolean;
  onClose: () => void;
  description: string;
}

const CommonModal: React.FC<CommonModalProps> = ({
  isOpen,
  onClose,
  description,
}) => {
  return (
    <Modal id="modal-1" isOpen={isOpen} onRequestClose={onClose}>
      <p className="p-5 text-sm text-center">{description}</p>
      <Button className="w-full mt-4" variant="brand" onClick={onClose}>
        <a href="/account" className="text-white">
          Go to Account
        </a>
      </Button>
    </Modal>
  );
};

export default CommonModal;
