import React, { FC, ReactNode } from "react";
import { StayDataType } from "data/types";
import StayCardV5 from "components/StayCard/StayCardV5";

export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  setAllData: React.Dispatch<React.SetStateAction<StayDataType[]>>;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  fetchOldData: () => Promise<void>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  isLoading: boolean; // Add isLoading prop
}

const SectionGridFeaturePlacesV4: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = [],
  gridClass = "",
  setAllData,
  isLoading, // Destructure isLoading
}) => {
  const renderCard = (stay: StayDataType) => {
    return (
      <StayCardV5
        key={stay.id}
        data={stay}
        setAllData={setAllData}
        stayListings={stayListings}
      />
    );
  };

  if (!isLoading && stayListings?.length === 0) {
    return (
      <div className="nc-PageHome relative overflow-hidden">
        <div className="container relative">
          <div className="min-h-screen flex items-center justify-center">
            <div className="text-center">
              <h2 className="text-2xl font-bold text-neutral-900 dark:text-neutral-100">
                No Listings Found
              </h2>
              <p className="mt-2 text-neutral-500 dark:text-neutral-400">
                There are currently no properties listed.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 ${gridClass}`}
    >
      {stayListings.map((stay) => renderCard(stay))}
    </div>
  );
};

export default SectionGridFeaturePlacesV4;
