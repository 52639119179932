import LikeSaveBtns from "components/LikeSaveBtns";
import StartRating from "components/StartRating/StartRating";
import React from "react";
import Badge from "shared/Badge/Badge";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Avatar from "shared/Avatar/Avatar";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";

interface RenderSectionDetailsProps {
  propertyData: PropertyToEdit | null;
  slug: string;
}

const RenderSectionDetails: React.FC<RenderSectionDetailsProps> = ({
  propertyData,
  slug,
}) => {
  const { currentUser } = useSelector((state: RootState) => state.user);
  if (!propertyData) return null;

  return (
    <div
      className={`listingSection__wrap ${
        propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
      }`}
    >
      <div className="flex justify-between items-center">
        <Badge name={propertyData.property_type?.name || "Property"} />
        <LikeSaveBtns />
        {propertyData?.user?.is_host &&
          currentUser?.id === propertyData?.user?.id && (
            <ButtonSecondary href={`/add-listing-1/${slug}`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                />
              </svg>
              <span className="ml-3">Edit</span>
            </ButtonSecondary>
          )}
      </div>

      <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
        {propertyData.title}
      </h2>

      <div className="flex items-center space-x-4">
        <StartRating />
        <span>·</span>
        <span>
          <i className="las la-map-marker-alt"></i>
          <span className="ml-1">{propertyData.address}</span>
        </span>
      </div>

      <div className="flex items-center">
        <Avatar
          hasChecked
          sizeClass="h-10 w-10"
          radius="rounded-full"
          imgUrl={propertyData.user?.image}
        />
        <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
          Hosted by{" "}
          <span className="text-neutral-900 dark:text-neutral-200 font-medium">
            {`${propertyData.user?.first_name} ${propertyData.user?.last_name}`}
          </span>
        </span>
      </div>

      <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

      <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
        <div className="flex items-center space-x-3">
          <i className="las la-user text-2xl"></i>
          <span>
            {propertyData.guest_number}{" "}
            <span className="hidden sm:inline-block">guests</span>
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <i className="las la-door-open text-2xl"></i>
          <span>
            {propertyData.total_rooms}{" "}
            <span className="hidden sm:inline-block">rooms</span>
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <i className="las la-bath text-2xl"></i>
          <span>
            {propertyData.total_bathrooms}{" "}
            <span className="hidden sm:inline-block">baths</span>
          </span>
        </div>
        <div className="flex items-center space-x-3">
          <i className="las la-expand-arrows-alt text-2xl"></i>
          <span>
            {propertyData.property_size}{" "}
            <span className="hidden sm:inline-block">sq ft</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default RenderSectionDetails;
