"use client";
// @ts-ignore
import { MapPinIcon } from "@heroicons/react/24/outline";
import { useState, useRef, useEffect, FC } from "react";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useLoadScript } from "@react-google-maps/api";
import "./LocationInput.scss";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export interface LocationInputProps {
  placeHolder?: string;
  desc?: string;
  className?: string;
  divHideVerticalLineClass?: string;
  autoFocus?: boolean;
  setUserLocation?: (location: { latitude: number; longitude: number, locationData: any }) => void;
  setSelectedLocation?: (value: string) => void;
  error?: string;
  setError?: (value: string) => void;
}

const LocationInput: FC<LocationInputProps> = ({
  autoFocus = false,
  className = "nc-flex-1.5",
  divHideVerticalLineClass = "left-10 -right-0.5",
  setUserLocation,
  setSelectedLocation,
  error,
  setError,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [showPopover, setShowPopover] = useState(autoFocus);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || "",
    libraries: ["places"],
  });

  useEffect(() => {
    setShowPopover(autoFocus);
  }, [autoFocus]);

  useEffect(() => {
    if (eventClickOutsideDiv) {
      document.removeEventListener("click", eventClickOutsideDiv);
    }
    showPopover && document.addEventListener("click", eventClickOutsideDiv);
    return () => {
      document.removeEventListener("click", eventClickOutsideDiv);
    };
  }, [showPopover]);

  useEffect(() => {
    if (showPopover && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showPopover]);

  const eventClickOutsideDiv = (event: MouseEvent) => {
    if (!containerRef.current) return;
    if (!showPopover || containerRef.current.contains(event.target as Node)) {
      return;
    }
    setShowPopover(false);
  };

  const handlePlaceSelect = async (place: any) => {
    try {
      if (!place || !place.value || !place.value.place_id) return;
      setError && setError("");
      setSelectedLocation && setSelectedLocation(place);

      const placeId = place.value.place_id;
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?place_id=${placeId}&key=${process.env.REACT_APP_GOOGLE_MAP_KEY}`
      );

      if (!response.ok) {
        throw new Error(`API error: ${response.status} ${response.statusText}`);
      }

      const geocodeData = await response.json();
      if (geocodeData.status !== "OK") {
        throw new Error(`Google API Error: ${geocodeData.status}`);
      }

      if (setUserLocation) {
        const { lat, lng } = geocodeData.results[0].geometry.location;
        const addressComponents = geocodeData.results[0].address_components;
        const formattedAddress = geocodeData.results[0].formatted_address;

        let city = "";
        let state = "";
        let zipCode = "";
        let country = "";

        addressComponents.forEach((component: any) => {
          if (component.types.includes("locality")) {
            city = component.long_name;
          }
          if (component.types.includes("administrative_area_level_1")) {
            state = component.long_name;
          }
          if (
            component.types.includes("postal_code") ||
            component.types.includes("postal_code_prefix")
          ) {
            zipCode = component.long_name;
          }
          if (component.types.includes("country")) {
            country = component.long_name;
          }
        });
        const locationData = {
          street: formattedAddress || `${city}, ${state}, ${country}`,
          city: city || "N/A",
          state: state || "N/A",
          zipcode: zipCode || "N/A",
          country: country || "N/A",
        }
        setUserLocation({ latitude: lat, longitude: lng , locationData: locationData});
      }
    } catch (error) {
      console.error("Geocoding error:", error);
    }
  };


  return (
    <div className={`relative flex flex-col ${className}`} ref={containerRef}>
      <div
        onClick={() => setShowPopover(true)}
        className={`flex z-10 flex-1 relative nc-hero-field-padding flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left ${
          showPopover ? "nc-hero-field-focused" : ""
        }`}
      >
        <div className="text-neutral-700 dark:text-neutral-300">
          <MapPinIcon className="w-5 h-5 lg:w-7 lg:h-7" />
        </div>
        <div className="flex-grow">
          {!isLoaded ? (
            <Skeleton height={40} width="100%" className="rounded-md" />
          ) : (
            <GooglePlacesAutocomplete
              apiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""}
              selectProps={{
                placeholder: "Search for places...",
                onChange: (place) => {
                  if (place && place.value && place.value.place_id) {
                    handlePlaceSelect(place);
                  }
                },
                className: "font-semibold text-lg",
                styles: {
                  control: (provided) => ({
                    ...provided,
                    border: "none",
                    boxShadow: "none",
                    background: "transparent",
                  }),
                  input: (provided) => ({
                    ...provided,
                    color: "inherit",
                    ".dark &": { color: "white" },
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "black",
                    ".dark &": {
                      color: "white",
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    backgroundColor: state.isFocused
                      ? "#E5E7EB"
                      : "transparent",
                    color: "inherit",
                    ".dark &": {
                      backgroundColor: state.isFocused
                        ? "rgba(255, 255, 255, 0.2)"
                        : "transparent",
                    },
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: "var(--bg-color, #ffffff)",
                    marginTop: "26px",
                    marginLeft: "6px",
                    ".dark &": {
                      backgroundColor:
                        "rgba(var(--c-neutral-800), var(--tw-bg-opacity, 1))",
                      color: "#ffffff",
                    },
                  }),
                  singleValue: (provided) => ({
                    ...provided,
                    color: "inherit",
                    ".dark &": { color: "white" },
                  }),
                },
              }}
            />
          )}

          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        </div>
      </div>
      {showPopover && (
        <div
          className={`h-8 absolute self-center top-1/2 -translate-y-1/2 z-0 bg-white dark:bg-neutral-800 ${divHideVerticalLineClass}`}
        ></div>
      )}
    </div>
  );
};

export default LocationInput;
