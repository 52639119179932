import { FC, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  deleteInvoice,
  getBookingsDetails,
  postChatBooking,
} from "../../redux/reducers/Properties/PropertiesSlice";
import { AppDispatch, RootState } from "redux/store";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useLocation } from "react-router-dom";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { BookingRequestDetails } from "redux/reducers/Properties/PropertiesSliceTypes";
import Invoice from "../Booking/Invoice";
import InvoiceUser from "../Booking/InvoiceUser";
import successHandler from "utils/helpers/SuccessHandler";
import { useSelector } from "react-redux";
import { updateBookingsDetails } from "../../redux/reducers/Payments/PaymentReducer";
import { currencySymbol } from "shared/CurrencySymbol";
import moment from "moment";
import NcImage from "shared/NcImage/NcImage";
import { getMessage } from "../../redux/reducers/UserSlice/UserSlice";
import MessageList from "containers/Messages/MessageList";
import "../Messages/MessageList.scss";
import "react-loading-skeleton/dist/skeleton.css";
import BookingDetailsSkeleton from "shared/Skelton/CommonSkelton";

export interface BookingRequest {
  className?: string;
  // data?: StayDataType;
  // size?: "default" | "small";
}

const BookingRequest: FC<BookingRequest> = ({ className = "" }) => {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [bookingRequestDetails, setBookingRequestDetails] = useState<
    BookingRequestDetails | any
  >(null);
  const [replyText, setReplyText] = useState("");
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInvoice, setShowInvoice] = useState(false);
  const [showUserInvoice, setShowUserInvoice] = useState(false);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const openInvoice = () => setShowInvoice(true);
  const closeInvoice = () => setShowInvoice(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [chatMessages, setChatMessages] = useState<any[]>([]);
  const chatId = bookingRequestDetails?.chat?.id;
  const modifiedBookingMap = chatMessages;
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await fetchAllManageBooking();
      setLoading(false);
    };

    fetchData();
  }, [dispatch]);

  const fetchMessages = async (pge: number) => {
    const data = {
      page: pge,
      chatId: bookingRequestDetails?.chat?.id,
    };
    setShowMore(true);

    try {
      const response = await dispatch(getMessage(data)).unwrap();
      setChatMessages(response || []);
    } catch (err: any) {
    } finally {
      setShowMore(false);
    }
  };

  useEffect(() => {
    if (chatId) {
      fetchMessages(+1);
    }
  }, [chatId]);

  const checkInDate = bookingRequestDetails?.check_in_date
    ? new Date(bookingRequestDetails.check_in_date).toLocaleDateString(
        "en-US",
        {
          month: "long",
          day: "numeric",
          year: "numeric",
        }
      )
    : "";

  const checkOutDate = bookingRequestDetails?.check_out_date
    ? new Date(bookingRequestDetails.check_out_date).toLocaleDateString(
        "en-US",
        {
          month: "long",
          day: "numeric",
          year: "numeric",
        }
      )
    : "";

  const handleFileChange = (e: { target: { files: FileList | null } }) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setSelectedFile(file);
      }
    }
  };

  const fetchAllManageBooking = async () => {
    const action = await dispatch(getBookingsDetails(id));
    if (action?.payload?.success) {
      setBookingRequestDetails(action.payload.booking);
    }
  };

  const handleSubmit = async () => {
    if (!replyText.trim() && !selectedFile) return;
    setIsSubmitting(true);

    try {
      const formData = new FormData();
      formData.append("message[body]", replyText);
      if (selectedFile) {
        formData.append("file", selectedFile);
        formData.append("attachment", selectedFile);
        formData.append("message[attachments][]", selectedFile);
      }

      await dispatch(
        postChatBooking({
          chatId: bookingRequestDetails?.chat?.id,
          formData,
        })
      ).unwrap();
      setReplyText("");
      setSelectedFile(null);
      await fetchAllManageBooking();
      fetchMessages(1);
    } catch (error) {
      console.error("Failed to send reply:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCancelBooking = async () => {
    if (id == null) {
      console.error("ID is null or undefined");
      return;
    }

    try {
      const action = await dispatch(
        updateBookingsDetails({ id, status: "canceled" })
      );
      if (updateBookingsDetails.fulfilled.match(action)) {
        successHandler("Booking canceled successfully:", action.payload);
        await fetchAllManageBooking();
      } else {
        console.error("Failed to cancel booking:", action.payload);
      }
    } catch (error) {
      console.error("Error canceling booking:", error);
    }
  };

  const handleDeleteInvoice = async () => {
    if (id == null) {
      console.error("ID is null or undefined");
      return;
    }

    setIsDeleting(true);

    const postData = {
      bookingId: id,
      id: bookingRequestDetails?.invoice?.id,
      _destroy: true,
      status: "canceled",
    };

    try {
      const action = await dispatch(deleteInvoice(postData));
      if (deleteInvoice.fulfilled.match(action)) {
        successHandler("Invoice deleted successfully:", action.payload);
        await fetchAllManageBooking();
      } else {
        console.error("Failed to delete invoice:", action.payload);
      }
    } catch (error) {
      console.error("Error deleting invoice:", error);
    } finally {
      setIsDeleting(false);
    }
  };

  const renderSliderGallery = () => (
    <div className="w-full">
      <GallerySlider
        uniqueID={`StayCard_${id}`}
        ratioClass="aspect-w-4 aspect-h-3"
        galleryImgs={bookingRequestDetails?.property?.place_images || []}
        href={"#"}
      />
    </div>
  );

  const pathArray = [
    { path: 1, type: "Receive a message", status: "canceled" },
    { path: 2, type: "Send invitation to book", status: "booking_request" },
    { path: 3, type: "Wait for confirmation", status: "invitation_sent" },
    {
      path: 4,
      type: "Make payment of first month",
      status: "invoice_sent",
    },
    { path: 5, type: "Booking confirmed", status: "confirmed" },
  ];

  if (loading) {
    return <BookingDetailsSkeleton />;
  }
  return (
    <div className="max-w-7xl mx-auto p-4 lg:container ">
      <h1 className="text-xl font-bold text-gray-700 dark:text-gray-200 mb-6">
        MESSAGE FROM{" "}
        {currentUser?.is_host
          ? bookingRequestDetails?.chat?.sender?.name || "Yopmail"
          : bookingRequestDetails?.chat?.receiver?.name || "Host"}
      </h1>
      <div className="mx-auto grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:col-span-2 mb-8">
          <div className="lg:col-span-2">
            <div className="relative flex flex-col lg:flex-row justify-between items-center border border-gray-300 dark:bg-black  p-0.8  pt-4 md:p-8 text-xl  backdrop-blur-lg shadow-xl rounded-lg  ">
              <div className="relative flex flex-col md:flex-row justify-between items-center w-full">
                {pathArray.map((step, index) => {
                  const activeStepIndex = pathArray.findIndex(
                    (s) => s.status === bookingRequestDetails?.status
                  );

                  return (
                    <div
                      key={step.path}
                      className="flex flex-col  items-center md:w-1/5 w-full mb-4 md:mb-0 relative z-10"
                    >
                      <div
                        className={`w-4 h-4 rounded-full flex items-center justify-center mb-2 text-xs p-4  ${
                          index <= activeStepIndex
                            ? "bg-orange-500 text-white"
                            : "bg-gray-300 dark:bg-gray-700 text-gray-500"
                        }`}
                      >
                        {step.path}
                      </div>
                      <span className="text-xs text-center dark:text-gray-300 w-[105px]">
                        {step.path === 1 ? (
                          currentUser?.is_host ? (
                            "Receive a message"
                          ) : (
                            <>
                              Send
                              <br />a message
                            </>
                          )
                        ) : step.path === 2 ? (
                          currentUser?.is_host ? (
                            "Send invitation to book"
                          ) : (
                            <>
                              Send
                              <br />
                              booking request
                            </>
                          )
                        ) : (
                          step.type
                        )}
                      </span>
                      {index < pathArray.length - 1 && (
                        <div
                          className={`absolute top-1 left-full transform -translate-x-1/2 h-[2px] w-[60%] sm:w-3/4 mt-2 hidden md:block ${
                            index < activeStepIndex
                              ? "bg-orange-500"
                              : index === activeStepIndex - 1
                              ? "bg-orange-500"
                              : "bg-gray-300 dark:bg-gray-700"
                          }`}
                        ></div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="bg-white dark:bg-black shadow-lg rounded-lg p-8 mt-5 border border-gray-300 dark:border-gray-700">
              <h2 className="font-semibold mb-4 text-xl text-gray-700 dark:text-gray-200">
                Booking Request {bookingRequestDetails?.number} for{" "}
                {bookingRequestDetails?.property?.title}
              </h2>

              <div className="space-y-4 mb-6">
                <div className="flex justify-between">
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    Period :
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {moment(checkInDate).format("MMM DD, YYYY")} to{" "}
                    {moment(checkOutDate).format("MMM DD, YYYY")}
                  </span>
                </div>
                {bookingRequestDetails?.invoice?.id && (
                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Invoice No :
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {bookingRequestDetails?.invoice?.id}
                    </span>
                  </div>
                )}

                <div className="flex justify-between">
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    Pay Amount :
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {`${currencySymbol()} ${
                      Number(bookingRequestDetails?.total)?.toFixed(2) || 0
                    }`}
                  </span>
                </div>
                <div className="flex justify-between">
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    Guests :
                  </span>
                  <span className="text-sm text-gray-500 dark:text-gray-400">
                    {bookingRequestDetails?.number_of_guests}
                  </span>
                </div>
              </div>

              <div className="bg-gray-100 dark:bg-gray-700 p-6 rounded-lg">
                <div className="flex flex-col sm:flex-row gap-4">
                  {bookingRequestDetails?.status === "confirmed" ? (
                    <>
                      <ButtonPrimary
                        className="bg-green-500 text-white px-6 py-3 rounded-lg cursor-not-allowed"
                        disabled
                      >
                        Booking Confirmed
                      </ButtonPrimary>
                      {currentUser?.is_student &&
                        bookingRequestDetails?.property?.slug && (
                          <ButtonPrimary
                            href={`/listing-stay-detail/${bookingRequestDetails.property.slug}`}
                            className="bg-orange-500 text-white px-6 py-3 rounded-lg"
                          >
                            Visit Property
                          </ButtonPrimary>
                        )}
                    </>
                  ) : currentUser?.is_host ? (
                    <>
                      {bookingRequestDetails?.status !== "canceled" &&
                        (bookingRequestDetails?.status === "invoice_sent" ? (
                          <>
                            <ButtonPrimary
                              className="bg-gray-500 text-white px-6 py-3 rounded-lg cursor-not-allowed"
                              disabled
                            >
                              Invoice Issued
                            </ButtonPrimary>

                            {bookingRequestDetails?.invoice?.id != null && (
                              <ButtonPrimary
                                onClick={handleDeleteInvoice}
                                disabled={isDeleting}
                                className={`bg-red-500 text-white px-6 py-3 rounded-lg ${
                                  isDeleting
                                    ? "opacity-50 cursor-not-allowed"
                                    : "hover:bg-red-600"
                                }`}
                              >
                                {isDeleting ? "Deleting..." : "Delete Invoice"}
                              </ButtonPrimary>
                            )}
                          </>
                        ) : (
                          <ButtonPrimary
                            className="bg-orange-500 text-white px-6 py-3 rounded-lg"
                            onClick={openInvoice}
                          >
                            Issue Invoice
                          </ButtonPrimary>
                        ))}
                      <ButtonPrimary
                        className="bg-orange-500 text-white px-6 py-3 rounded-lg"
                        onClick={handleCancelBooking}
                        disabled={bookingRequestDetails?.status === "canceled"}
                      >
                        Reject Booking Request
                      </ButtonPrimary>
                    </>
                  ) : (
                    <>
                      {bookingRequestDetails?.status === "canceled" ? (
                        <button
                          className="bg-gray-300 dark:bg-gray-600 px-6 py-3 rounded-lg text-gray-700 dark:text-gray-300 cursor-not-allowed"
                          disabled
                        >
                          Request Pending
                        </button>
                      ) : bookingRequestDetails?.status ===
                        "booking_request" ? (
                        <button
                          className="bg-gray-300 dark:bg-gray-600 px-6 py-3 rounded-lg text-gray-700 dark:text-gray-300 cursor-not-allowed"
                          disabled
                        >
                          Request Pending
                        </button>
                      ) : bookingRequestDetails?.status === "invoice_sent" ? (
                        <button
                          className="bg-green-500 text-white px-6 py-3 rounded-lg"
                          onClick={() => setShowUserInvoice(true)}
                        >
                          Received Invoice - Check & Pay
                        </button>
                      ) : null}

                      <ButtonPrimary
                        className="bg-orange-500 text-white px-6 py-3 rounded-lg"
                        onClick={handleCancelBooking}
                        disabled={bookingRequestDetails?.status === "canceled"}
                      >
                        Cancel Booking Request
                      </ButtonPrimary>

                      {bookingRequestDetails?.status !== "canceled" && (
                        <ButtonPrimary
                          href={`/listing-stay-detail/${bookingRequestDetails?.property?.slug}`}
                          className="bg-orange-500 text-white px-6 py-3 rounded-lg"
                        >
                          Visit Property
                        </ButtonPrimary>
                      )}
                    </>
                  )}
                </div>

                {showUserInvoice && (
                  <InvoiceUser bookingRequestDetails={bookingRequestDetails} />
                )}
                {showInvoice && (
                  <Invoice
                    bookingRequestDetails={bookingRequestDetails}
                    closeInvoice={closeInvoice}
                    callback={fetchAllManageBooking}
                  />
                )}
              </div>
              <div className="mt-6 p-6 bg-gray-100 dark:bg-black rounded-lg">
                <span className="text-gray-500 dark:text-gray-400">Reply</span>
                <textarea
                  className="w-full border border-gray-300 dark:border-gray-700 rounded-lg p-4 min-h-[80px] focus:outline-none focus:border-gray-400 dark:focus:border-gray-600 mt-4 bg-white dark:bg-gray-900 text-gray-700 dark:text-gray-300"
                  placeholder="Write your reply..."
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value)}
                  disabled={isSubmitting}
                />
                <div className="flex justify-end items-center mt-4 gap-6">
                  <label
                    htmlFor="cover-image-upload"
                    className="text-blue-500 dark:text-blue-400 text-sm cursor-pointer flex items-center gap-2"
                  >
                    📎 <span>Upload file</span>
                    <input
                      id="cover-image-upload"
                      name="cover-image-upload"
                      type="file"
                      className="sr-only"
                      onChange={handleFileChange}
                      disabled={isSubmitting}
                    />
                  </label>
                  {selectedFile && (
                    <span className="text-sm text-gray-600 dark:text-gray-400">
                      {selectedFile?.name}
                    </span>
                  )}
                  <button
                    onClick={handleSubmit}
                    disabled={
                      isSubmitting || (!replyText.trim() && !selectedFile)
                    }
                    className={`bg-orange-500 text-white px-6 py-3 rounded-lg ${
                      isSubmitting || (!replyText.trim() && !selectedFile)
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:bg-orange-600"
                    }`}
                  >
                    {isSubmitting ? "Sending..." : "Send Reply"}
                  </button>
                </div>
              </div>
              <div className="pt-6">
                <MessageList
                  modifiedBookingMap={modifiedBookingMap}
                  loadMoreMessages={fetchMessages}
                  showMore={showMore}
                />
              </div>
            </div>
          </div>
        </div>
        <div>
          {currentUser?.is_host ? (
            <div className="relative bg-orange-500 backdrop-blur-lg shadow-xl rounded-lg p-6 w-full min-h-96">
              <div className="flex flex-col items-center">
                <div className="relative w-24 h-24 mb-4">
                  {bookingRequestDetails?.user?.image ? (
                    <NcImage
                      src={bookingRequestDetails.user.image}
                      alt="User"
                      className="w-24 h-24 rounded-full object-cover border-4 border-orange-500 shadow-md"
                    />
                  ) : (
                    <div className="w-24 h-24 flex items-center justify-center bg-orange-600 text-white text-4xl font-bold rounded-full shadow-md">
                      {bookingRequestDetails?.user?.first_name
                        ?.charAt(0)
                        ?.toUpperCase() || "U"}
                    </div>
                  )}
                </div>
                <h3 className="font-bold text-lg text-white dark:text-orange-300">
                  {bookingRequestDetails?.user?.first_name || "Host"}
                </h3>
                <div className="mt-3 w-full space-y-3 text-center">
                  <p className="text-sm text-white dark:text-gray-300">
                    {bookingRequestDetails?.user?.speak_language
                      ? `🗣️ Language: ${bookingRequestDetails.user.speak_language}`
                      : "🗣️ Language: Not available"}
                  </p>
                  <div className="flex flex-row items-center justify-center">
                    <p className="flex flex-row sm:flex-col xl:flex-row text-sm text-white dark:text-gray-300 flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 8l8.293 5.297a1 1 0 0 0 1.414 0L21 8m0 0L12 14.5 3 8m18-2h-4a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2H1m20 0v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6"
                        />
                      </svg>
                      <span className="mr-2">Email:</span>
                      {bookingRequestDetails?.status === "confirmed" && (
                        <span className="text-sm text-white dark:text-gray-300">
                          {bookingRequestDetails?.property_user?.email}
                        </span>
                      )}
                      <span
                        className={
                          bookingRequestDetails?.user?.email_verified
                            ? "text-green-400 font-medium"
                            : "text-red-400 font-medium"
                        }
                      >
                        {bookingRequestDetails?.user?.email_verified
                          ? "✓ Verified"
                          : "✘ Not Verified"}
                      </span>
                    </p>
                  </div>
                  {bookingRequestDetails?.status === "confirmed" && (
                    <div className="flex items-center justify-center">
                      <p className="text-sm text-white dark:text-gray-300 flex items-center">
                        <span className="mr-2">📞</span>
                        <span className="mr-2">Phone:</span>
                        <span className="text-sm text-white dark:text-gray-300">
                          {bookingRequestDetails?.property_user?.phone}
                        </span>
                      </p>
                    </div>
                  )}
                  <p className="text-sm text-white dark:text-gray-300">
                    {bookingRequestDetails?.user?.live_in
                      ? `🌍 Nationality: ${bookingRequestDetails.user.live_in}`
                      : "🌍 Nationality: Not available"}
                  </p>
                  <div className="flex items-center justify-center w-full max-h-28 overflow-y-auto scrollbar-thin scrollbar scrollbar-thumb-blue-600 scrollbar-track-gray-100 custom-design ">
                    <p className="max-w-[90%] text-sm text-white dark:text-gray-300 flex break-words break-all">
                      <span className="text-white-400 font-medium h-[88px]">
                        {bookingRequestDetails?.user?.about_me}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="relative bg-orange-500 backdrop-blur-lg shadow-xl rounded-lg p-6 w-full min-h-96">
              <div className="mt-3 flex flex-col items-center">
                <div className="relative w-24 h-24 mb-4">
                  {bookingRequestDetails?.property_user?.image ? (
                    <NcImage
                      src={bookingRequestDetails?.property_user?.image}
                      alt="User"
                      className="w-24 h-24 rounded-full object-cover border-4 border-orange-500 shadow-md"
                    />
                  ) : (
                    <div className="w-24 h-24 flex items-center justify-center bg-orange-600 text-white text-4xl font-bold rounded-full shadow-md">
                      {bookingRequestDetails?.property_user?.first_name
                        .charAt(0)
                        ?.toUpperCase() || "H"}
                    </div>
                  )}
                </div>
                <h3 className="font-bold text-lg text-white dark:text-orange-300">
                  {bookingRequestDetails?.property_user?.first_name ||
                    "Guest User"}
                </h3>
                <div className="mt-3 w-full space-y-3 text-center">
                  <p className="text-sm text-white dark:text-gray-300">
                    {bookingRequestDetails?.property_user?.speak_language
                      ? `🗣️ Language: ${bookingRequestDetails?.property_user?.speak_language}`
                      : "🗣️ Language: Not available"}
                  </p>
                  <div className="flex flex-row items-center justify-center">
                    <p className="flex flex-row sm:flex-col xl:flex-row text-sm text-white dark:text-gray-300 items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5 mr-2"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 8l8.293 5.297a1 1 0 0 0 1.414 0L21 8m0 0L12 14.5 3 8m18-2h-4a2 2 0 0 0-2-2H7a2 2 0 0 0-2 2H1m20 0v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V6"
                        />
                      </svg>
                      <span className="mr-2">Email:</span>
                      {bookingRequestDetails?.status === "confirmed" && (
                        <span className="text-sm text-white dark:text-gray-300">
                          {bookingRequestDetails?.property_user?.email}
                        </span>
                      )}
                      <span
                        className={
                          bookingRequestDetails?.property_user?.email_verified
                            ? "text-green-400 font-medium"
                            : "text-red-400 font-medium"
                        }
                      >
                        {bookingRequestDetails?.property_user?.email_verified
                          ? "✓ Verified"
                          : "✘ Not Verified"}
                      </span>
                    </p>
                  </div>

                  {bookingRequestDetails?.status === "confirmed" && (
                    <div className="flex items-center justify-center">
                      <p className="text-sm text-white dark:text-gray-300 flex items-center">
                        <span className="mr-2">📞</span>
                        <span className="mr-2">Phone:</span>
                        <span className="text-sm text-white dark:text-gray-300">
                          {bookingRequestDetails?.property_user?.phone}
                        </span>
                      </p>
                    </div>
                  )}
                  <p className="text-sm text-white dark:text-gray-300">
                    {bookingRequestDetails?.property_user?.live_in
                      ? `🌍 Nationality: ${bookingRequestDetails?.property_user?.live_in}`
                      : "🌍 Nationality: Not available"}
                  </p>
                  <div className="flex items-center justify-center w-full max-h-28 overflow-y-auto scrollbar-thin scrollbar scrollbar-thumb-blue-600 scrollbar-track-gray-100 custom-design ">
                    <p className="max-w-[90%] text-sm text-white dark:text-gray-300 flex break-words break-all">
                      <span className="text-white-400 font-medium h-[88px]">
                        {bookingRequestDetails?.property_user?.about_me}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="w-full mt-5">
            <div className="p-6 w-full flex flex-col items-center justify-center relative bg-white dark:bg-black backdrop-blur-lg shadow-xl rounded-lg ">
              <div className="space-y-4 w-full">
                <div className="space-y-1">
                  <h2 className="text-xl font-semibold text-gray-800 dark:text-gray-100">
                    {bookingRequestDetails?.property?.title}
                  </h2>
                  <p className="text-gray-600 dark:text-gray-400">
                    {bookingRequestDetails?.property?.address}
                  </p>
                </div>
                <div
                  className="nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow w-full"
                  data-nc-id=""
                >
                  {renderSliderGallery()}
                </div>

                <div className="space-y-3 w-full">
                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Period :
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {moment(checkInDate).format("MMM DD, YYYY")} to{" "}
                      {moment(checkOutDate).format("MMM DD, YYYY")}
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      No. of Months :
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                    {bookingRequestDetails?.no_of_months || "N/A"}
                    </span>
                  </div>

                  <div className="flex justify-between">
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Guests :
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {bookingRequestDetails?.number_of_guests}
                    </span>
                  </div>

                  <hr className="border-gray-200 dark:border-gray-700" />
                  <div className="space-y-2">
                    <>
                      {bookingRequestDetails?.item_total > 0 && (
                        <div className="flex justify-between">
                          <p className="text-sm text-gray-500 dark:text-gray-400">
                            First Month's Rent :
                          </p>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {`${currencySymbol()} ${Number(
                              bookingRequestDetails?.item_total
                            )?.toFixed(2)}`}
                          </span>
                        </div>
                      )}

                      {bookingRequestDetails?.service_tax > 0 && (
                        <div className="flex justify-between">
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            One-Time Service Fee :
                          </span>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {`${currencySymbol()} ${Number(
                              bookingRequestDetails?.service_tax
                            )?.toFixed(2)}`}
                          </span>
                        </div>
                      )}

                      {bookingRequestDetails?.calculate_cleaning_fee > 0 && (
                        <div className="flex justify-between">
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            Cleaning Fee :
                          </span>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {`${currencySymbol()} ${Number(
                              bookingRequestDetails?.calculate_cleaning_fee
                            )?.toFixed(2)}`}
                          </span>
                        </div>
                      )}

                      {bookingRequestDetails?.calculate_city_fee > 0 && (
                        <div className="flex justify-between">
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            City Fee :
                          </span>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {`${currencySymbol()} ${Number(
                              bookingRequestDetails?.calculate_city_fee
                            )?.toFixed(2)}`}
                          </span>
                        </div>
                      )}
                      {bookingRequestDetails?.extra_guest_amount > 0 && (
                        <div className="flex justify-between">
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            Extra guest amount :
                          </span>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {`${currencySymbol()} ${Number(
                              bookingRequestDetails?.extra_guest_amount
                            )?.toFixed(2)}`}
                          </span>
                        </div>
                      )}

                      {bookingRequestDetails?.tax_total > 0 && (
                        <div className="flex justify-between">
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            Total Tax :
                          </span>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {`${currencySymbol()} ${Number(
                              bookingRequestDetails?.tax_total
                            )?.toFixed(2)}`}
                          </span>
                        </div>
                      )}

                      {bookingRequestDetails?.invoice_total > 0 && (
                        <div className="flex justify-between">
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            Invoice total :
                          </span>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {`${currencySymbol()} ${Number(
                              bookingRequestDetails?.invoice_total
                            )?.toFixed(2)}`}
                          </span>
                        </div>
                      )}
                    </>

                    {bookingRequestDetails?.total_amount && (
                      <div className="flex justify-between pt-4">
                        <span className="text-xl font-semibold text-gray-800 dark:text-gray-100">
                          Total
                        </span>
                        <span className="text-xl font-semibold text-gray-800 dark:text-gray-100">
                          {`${currencySymbol()} ${Number(
                            bookingRequestDetails?.total_amount
                          )?.toFixed(2)}`}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingRequest;
