import { FC } from "react";
import Dropdown, { Option } from "react-dropdown";
import "react-dropdown/style.css";

export interface GuestsInputProps {
  className?: string;
  room: any;
  maxGuests: number;
  formik?: any;
}

const SelectGuests: FC<GuestsInputProps> = ({
  className = "flex-1",
  room,
  maxGuests,
  formik,
}) => {
  const options = Array.from({ length: maxGuests }, (_, i) =>
    (i + 1).toString()
  );

  const guests = formik?.values?.number_of_guests;
  const selectedRoom = formik?.values?.room_id;

  const onChange = (value: Option, roomId: number) => {
    formik.setFieldValue("room_id", roomId);
    formik.setFieldValue("number_of_guests", value.value);
  };

  return (
    <>
      <style>
        {`
          .custom-dropdown .Dropdown-control {
            border-radius: 20px !important;
            border: 1px solid rgb(80, 85, 95, 0.5) !important;
            padding: 8px;
            width: 100%;
          }

           .custom-dropdown .Dropdown-placeholder {
            color: #889; 
            font-weight: 500;
          }

           .dark .custom-dropdown .Dropdown-placeholder {
              color: white !important; /* Dark mode color */
          }

          .Dropdown-control {
            color: #aaa; 
            background-color:inherit !important;
          }
          .custom-dropdown .Dropdown-arrow {
             border-top: 5px solid #52525B !important; 
          }

          .dark .custom-dropdown .Dropdown-arrow {
            border-top: 5px solid white !important;
          }
            
        `}
      </style>

      <div className={className}>
        <Dropdown
          options={options}
          onChange={(value) => onChange(value, room.id)}
          value={selectedRoom === room.id ? guests : undefined}
          placeholder="Please Select Guests"
          className="custom-dropdown"
          arrowClassName="Dropdown-arrow !top-[18px] mr-[6px]"
          placeholderClassName="Dropdown-placeholder is-selected  !text-[14px] ml-[8px] !text-[#52525B]"
        />
      </div>
    </>
  );
};

export default SelectGuests;
