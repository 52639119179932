import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

interface ListingStayDetailSkeletonProps {
  propertyData?: any;
}

const ListingStayDetailSkeleton: React.FC<ListingStayDetailSkeletonProps> = ({
  propertyData,
}) => {
  return (
    <div className="nc-ListingStayDetailPage">
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden">
            <Skeleton height={300} className="w-full h-full" />
          </div>
          <div>
            <Skeleton height={300} className="w-full h-full" />
          </div>
        </div>
      </header>

      <main className="relative z-10 mt-11 flex flex-col lg:flex-row">
    
        <div
          className={`listingSection__wrap ${
            propertyData?.is_shared_property ? "lg:w-7/10" : "lg:w-2/3"
          } w-full`}
        >
          <Skeleton height={200} />
          <Skeleton height={150} />
          <Skeleton height={200} />

          {propertyData?.is_shared_property && (
            <div className="flex justify-between space-x-4">
              <div className="w-[70%]">
                <Skeleton height={200} />
              </div>
              <div className="w-[30%]">
                <Skeleton height={350} />
              </div>
            </div>
          )}
        </div>

        {/* Sidebar Section */}
        {!propertyData?.is_shared_property && (
          <div className="lg:w-1/3 w-full mt-14 lg:mt-0 ml-10 listingSection__wrap ">
            <div className="sticky top-28">
              <Skeleton height={500} />
            </div>
          </div>
        )}
      </main>
    </div>
  );
};

export default ListingStayDetailSkeleton;
