import React from "react";
import "./CustomDatePicker.scss";
import {
  DatePickerInput,
  DatePickerType,
  DatePickerValue,
} from "@mantine/dates";

export interface CustomDatePickerV2Props<
  Type extends DatePickerType = "default"
> {
  label?: string;
  placeholder?: string;
  value: any;
  onChange: (value: DatePickerValue<Type>) => void;
  minDate?: Date;
  maxDate?: Date;
  excludeDate?: (date: Date) => boolean;
  renderDate?: (date: Date) => React.ReactNode;
}

const CustomDatePickerV2: React.FC<CustomDatePickerV2Props> = ({
  label,
  placeholder,
  value,
  onChange,
  minDate,
  maxDate,
  excludeDate,
}) => {
  return (
    <DatePickerInput
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      hideOutsideDates
      radius="xl"
      clearable
      valueFormat="MMM DD, YYYY"
      excludeDate={excludeDate}
      style={{zIndex: 1000}}
      renderDay={(date) => {
        const today = new Date();
        const isBeforeMinDate = minDate && date < minDate;
        const isAfterMaxDate = maxDate && date > maxDate;
        const isExcluded = excludeDate && excludeDate(date);

        const isDisabled =
        (isBeforeMinDate || isAfterMaxDate || isExcluded) &&
        !(date.toDateString() === today.toDateString() && !isExcluded);
        
        return (
          <div
            style={{
              position: "relative",
              textAlign: "center",
              fontSize: "14px",
            }}
          >
            {date.getDate()}
            {isDisabled && (
              <span
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "8px",
                  color: "red",
                  pointerEvents: "none",
                }}
              >
                ❌
              </span>
            )}
          </div>
        );
      }}
    />
  );
};

export default CustomDatePickerV2;
