import { FC, useEffect, useState } from "react";
import StartRating from "components/StartRating/StartRating";
import Badge from "shared/Badge/Badge";
// import SectionDateRange from "../SectionDateRange";
import { useLocation, useNavigate, useParams } from "react-router-dom";
//@ts-ignore
import { Squares2X2Icon } from "@heroicons/react/24/outline";

import ButtonPrimary from "shared/Button/ButtonPrimary";
import DetailPagetLayout from "../Layout";
import { getallupdateProperties } from "../../../redux/reducers/Properties/PropertiesSlice";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { useSelector } from "react-redux";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";
import { reserveBooking } from "../../../redux/reducers/Payments/PaymentReducer";
import ContactOwnerModal from "./contact-owner";
import successHandler from "utils/helpers/SuccessHandler";
import ErrorHandler from "utils/helpers/ErrorHandler";
import SelectGuests from "./SelectGuests";
import moment from "moment";
import { useFormik } from "formik";
import FormItem from "containers/PageAddListing1/FormItem";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import NcImage from "shared/NcImage/NcImage";
import GallerySliderV3 from "components/GallerySlider/GallerySliderV3";
import { currencySymbol } from "shared/CurrencySymbol";
import { eachDayOfInterval } from "date-fns";
import { isAuthenticated } from "utils/helpers/CommonHelper";
import CommonModal from "shared/Modal/CommonModal";
import ListingStayDetailSkeleton from "shared/Skelton/ListingDetailPageSkelton";
import "@mantine/dates/styles.css";
import CustomDatePickerV2 from "shared/DateField/CustomDatePickerV2";
import RenderSectionDetails from "./ListingStayDetailsPage/RenderSectionDetails";
import PropertyDescription from "./ListingStayDetailsPage/PropertyDescription";
import AmenitiesFeatures from "./ListingStayDetailsPage/AmenitiesFeatures";
import HostInformation from "./ListingStayDetailsPage/HostInformation";
import Review from "./ListingStayDetailsPage/Review";
import ListingMap from "./ListingStayDetailsPage/ListingMap";
import Cancellation from "./ListingStayDetailsPage/Cancellation";

const StayDetailPageContainer: FC<{}> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [propertyData, setPropertyData] = useState<PropertyToEdit | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const { globalId } = useSelector((state: RootState) => state.property);
  const { slug } = useParams();
  const id = slug;
  const propertyId = globalId || slug;

  const [isOpenModalAmenities, setIsOpenModalAmenities] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const thisPathname = useLocation().pathname;
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userLocation, setUserLocation] = useState<{
    lat: number;
    lng: number;
  }>({ lat: 38.9072, lng: -77.0369 });

  const [modalDescription, setModalDescription] = useState("");
  const [infoModal, setInfoModal] = useState(false);

  const handleChatButtonClick = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const handleSendMessage = (data: any) => {
    setIsModalOpen(false);
  };

  function closeModalAmenities() {
    setIsOpenModalAmenities(false);
  }

  function openModalAmenities() {
    setIsOpenModalAmenities(true);
  }

  const handleOpenModalImageGallery = () => {
    if (!propertyData) return;
    const data = JSON.stringify(propertyData?.place_images);
    navigate(
      `${thisPathname}/?type=PROPERTY_IMAGES&data=${encodeURIComponent(data)}`,
      {
        replace: true,
      }
    );
  };

  useEffect(() => {
    const fetchPropertyData = async () => {
      if (propertyId || id) {
        try {
          setLoading(true);
          const response: any = await dispatch(
            getallupdateProperties({ propertyId })
          );
          const property = response.payload?.property;
          const { latitude, longitude } = property;
          setUserLocation({ lat: Number(latitude), lng: Number(longitude) });

          if (property) {
            setPropertyData(property);
          } else {
            console.error("Property not found:", response);
          }
        } catch (error) {
          console.error("Error fetching property data:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchPropertyData();
  }, [propertyId, id, dispatch]);

  if (loading) {
    return <ListingStayDetailSkeleton propertyData={propertyData} />;
  }

  const RenderSection9 = () => {
    const [error, setError] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentRoomId, setCurrentRoomId] = useState<any>(null);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const location = useLocation();

    dayjs.extend(isBetween);

    const calculateMonthsDifference = (
      bookingStart: string,
      bookingEnd: string
    ): number => {
      const start = moment(bookingStart, "YYYY-MM-DD");
      const end = moment(bookingEnd, "YYYY-MM-DD");
      const uniqueMonths = new Set();
      let current = start.clone();
      while (current.isBefore(end, "day") || current.isSame(end, "day")) {
        uniqueMonths.add(`${current.year()}-${current.month() + 1}`);
        current.add(1, "day");
      }

      return uniqueMonths.size;
    };

    const handleSubmit = async (values: any) => {
      const postData = {
        booking: {
          number_of_guests: Number(values.number_of_guests),
          property_id: propertyId,
          check_in_date: moment(values.booking_start).format("YYYY-MM-DD"),
          check_out_date: moment(values.booking_end).format("YYYY-MM-DD"),
          no_of_months: calculateMonthsDifference(
            moment(values.booking_start).format("YYYY-MM-DD"),
            moment(values.booking_end).format("YYYY-MM-DD")
          ),
          line_items_attributes: [
            {
              room_id: values.room_id,
              price: values.price_per_month,
              quantity: 1,
              property_id: propertyId,
            },
          ],
        },
      };

      setIsLoading(true);
      try {
        const action = await dispatch(reserveBooking(postData as any));
        if (
          action?.type === "payment/reserveBooking/fulfilled" &&
          action.payload
        ) {
          successHandler(
            "Booking request sent. Please ! wait for owner's confirmation!!"
          );
        }
      } catch (error) {
        ErrorHandler("Failed to reserve booking. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    const modifiedStatus = (status: string | undefined) => {
      switch (status) {
        case "active":
          return "Active";
        case "inactive":
          return "Inactive";
        default:
          return "";
      }
    };

    const formik = useFormik({
      initialValues: {
        number_of_guests: null,
        booking_start: null,
        booking_end: null,
        room_id: null,
        line_items_attributes: [],
        price_per_month: null,
      },
      enableReinitialize: true,
      onSubmit: (values) => {
        const checkInDate = values.booking_start && new Date(values.booking_start);
        const checkOutDate = values.booking_end && new Date(values.booking_end);

        
        if (!isAuthenticated()) {
          navigate("/login", { state: { from: location.pathname } });
          return;
        }

        if (!currentUser?.profile_complete) {
          setInfoModal(true);
          setModalDescription(
            "Please complete your profile before making a booking."
          );
          return;
        }

        if(checkInDate && checkOutDate){
          if(checkOutDate < checkInDate){
            return setError("check in cannot be greater than check out");
          }
        }

        handleSubmit(values);
      },
    });

    const selectedRoom = formik?.values?.room_id;

    const handleContactOwner = (roomId: number) => {
      setCurrentRoomId(roomId);
      setIsModalOpen(true);
    };
    const handleCloseModal = () => {
      setIsModalOpen(false);
    };

    useEffect(() => {
      const { booking_start, booking_end, number_of_guests } = formik.values;
      if (booking_start && booking_end && number_of_guests) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }, [formik.values]);

    const getDisabledDates = (booked_dates: [string, string][]) => {
      const disabledDates: Date[] = [];

      booked_dates.forEach(([startDate, endDate]) => {
        if (startDate && endDate) {
          const dateRange = eachDayOfInterval({
            start: moment(startDate).toDate(),
            end: moment(endDate).toDate(),
          });
          disabledDates.push(...dateRange);
        }
      });

      return disabledDates;
    };

    if (!propertyData) return null;
    return (
      <div className="listingSection__wrap !space-y-10">
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          Rooms
        </h2>
        <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
        {propertyData.rooms.map((room, index) => (
          <div
            key={room.id}
            className={`${
              propertyData?.is_shared_property
                ? "flex flex-col lg:flex-row gap-4 w-full justify-between border border-gray-300/30 p-8 rounded-inherit mb-16"
                : ""
            }`}
          >
            <div
              className={`${
                propertyData?.is_shared_property ? "w-full lg:w-3/4 p-4" : ""
              }`}
            >
              <header className="rounded-md sm:rounded-xl">
                <div className="relative grid grid-cols-1">
                  <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-5">
                    {room?.name}
                  </h2>
                  <hr className="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700"></hr>
                  <div className="relative w-full rounded-2xl overflow-hidden will-change-transform">
                    <GallerySliderV3
                      uniqueID={`ExperiencesCard_${id}`}
                      ratioClass="aspect-w-4 aspect-h-3"
                      galleryImgs={room?.room_images.map((image) => image.url)}
                    />
                  </div>
                </div>
              </header>

              <div className="flex justify-between items-center mb-4 mt-5">
                <Badge name={modifiedStatus(room.status) || "Property"} />
              </div>
              <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300 mb-8">
                <div className="flex items-center space-x-3">
                  <i className="las la-user text-2xl"></i>
                  <span>{room.max_guests || "Default Value"}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <i className="las la-door-open text-2xl"></i>
                  <span>{room.room_type?.name || "Room Type"}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <i className="las la-bed text-2xl"></i>
                  <span>{room.bed_type?.name || "Bed Type"}</span>
                </div>
                <div className="flex items-center space-x-3">
                  <i className="las la-expand-arrows-alt text-2xl"></i>
                  <span>{room.size} sq ft</span>
                </div>
              </div>
              <div className="listingSection__wrap mb-8 w-full max-w-full">
                <h2 className="text-2xl font-semibold">Room Description</h2>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-4"></div>
                <div className="text-neutral-6000 dark:text-neutral-300 break-words">
                  <span>{room.description}</span>
                </div>
              </div>
              <div className="listingSection__wrap mb-8">
                <div>
                  <h2 className="text-2xl font-semibold">
                    Rooms Features & Amenities
                  </h2>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    About the property's amenities and services
                  </span>
                </div>
                <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 mb-6"></div>

                <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 text-sm text-neutral-700 dark:text-neutral-300">
                  {room.features.map((feature) => (
                    <div
                      key={feature.id}
                      className="flex items-center space-x-3"
                    >
                      <i className="text-3xl las la-check-circle"></i>
                      <span>{feature.name}</span>
                    </div>
                  ))}
                  {room.amenities.slice(0, 3).map((amenity) => (
                    <div
                      key={amenity.id}
                      className="flex items-center space-x-3"
                    >
                      <i className="text-3xl las la-check-circle"></i>
                      <span>{amenity.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div
              className={`${
                propertyData?.is_shared_property ? "w-full lg:w-1.8/4 pt-4" : ""
              }`}
            >
              {propertyData?.is_shared_property && (
                <div className="listingSectionSidebar__wrap shadow-xl">
                  {room.status === "inactive" && (
                    <Badge
                      name={
                        room.status === "inactive" ? "Room is Inactive" : ""
                      }
                    />
                  )}
                  <div className="flex justify-between">
                    <span className="text-3xl font-semibold">
                      {`${currencySymbol()} ${
                        Number(room?.price_per_month)?.toFixed(2) || 0
                      }`}
                      /month
                    </span>
                  </div>
                  <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col border border-neutral-200 dark:border-neutral-700 rounded-3xl"
                  >
                    <div className="pl-6 pr-6 pt-0 pb-6">
                      <CustomDatePickerV2
                        label="Availability Start Date"
                        placeholder="Availability Start Date"
                        value={
                          selectedRoom === room.id &&
                          formik.values.booking_start
                            ? moment(formik.values.booking_start)
                            : undefined
                        }
                        onChange={(value) => {
                          formik.setFieldValue("room_id", room.id);
                          formik.setFieldValue(
                            "price_per_month",
                            room.price_per_month
                          );
                          formik.setFieldValue(
                            "booking_end",
                            selectedRoom !== room.id && undefined
                          );
                          formik.setFieldValue(
                            "number_of_guests",
                            selectedRoom !== room.id && null
                          );
                          formik.setFieldValue("booking_start", value);
                        }}
                        minDate={moment(
                          moment(
                            room?.booking_start ??
                              propertyData?.availability_start
                          ).isAfter(moment())
                            ? room?.booking_start ??
                                propertyData?.availability_start
                            : new Date()
                        ).toDate()}
                        maxDate={
                          room?.booking_end
                            ? moment(room?.booking_end).toDate()
                            : propertyData?.availability_end
                            ? moment(propertyData?.availability_end).toDate()
                            : undefined
                        }
                        excludeDate={(date) => {
                          const disabledDates = getDisabledDates(
                            room.booked_dates
                          );
                          return disabledDates.some(
                            (disabledDate) =>
                              date.getFullYear() ===
                                disabledDate.getFullYear() &&
                              date.getMonth() === disabledDate.getMonth() &&
                              date.getDate() === disabledDate.getDate()
                          );
                        }}
                      />
                      <CustomDatePickerV2
                        label="Availability End Date"
                        placeholder="Availability End Date"
                        value={
                          selectedRoom === room.id && formik.values.booking_end
                            ? moment(formik.values.booking_end)
                            : undefined
                        }
                        onChange={(value) => {
                          formik.setFieldValue("room_id", room.id);
                          formik.setFieldValue(
                            "price_per_month",
                            room.price_per_month
                          );
                          formik.setFieldValue(
                            "booking_end",
                            selectedRoom !== room.id && undefined
                          );
                          formik.setFieldValue(
                            "number_of_guests",
                            selectedRoom !== room.id && null
                          );
                          formik.setFieldValue("booking_end", value);
                        }}
                        minDate={moment(
                          moment(
                            room?.booking_start ??
                              propertyData?.availability_start
                          ).isAfter(moment())
                            ? room?.booking_start ??
                                propertyData?.availability_start
                            : new Date()
                        ).toDate()}
                        maxDate={
                          room?.booking_end
                            ? moment(room?.booking_end).toDate()
                            : propertyData?.availability_end
                            ? moment(propertyData?.availability_end).toDate()
                            : undefined
                        }
                        excludeDate={(date) => {
                          const disabledDates = getDisabledDates(
                            room.booked_dates
                          );
                          return disabledDates.some(
                            (disabledDate) =>
                              date.getFullYear() ===
                                disabledDate.getFullYear() &&
                              date.getMonth() === disabledDate.getMonth() &&
                              date.getDate() === disabledDate.getDate()
                          );
                        }}
                      />
                      {selectedRoom === room.id && (
                        <span className="text-red-500 text-xs">{error}</span>
                      )}

                      <FormItem label="Select Guest" className="mt-4">
                        <SelectGuests
                          className={"flex-1"}
                          room={room}
                          maxGuests={room?.max_guests}
                          formik={formik}
                        />
                      </FormItem>
                    </div>
                  </form>
                  <div className="flex flex-col space-y-4">
                    <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
                      <span>Price Per Month</span>
                      <span>
                        {`${currencySymbol()} ${
                          Number(room?.price_per_month)?.toFixed(2) || 0
                        }`}
                        /month
                      </span>
                    </div>
                    <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                    <div className="flex justify-between font-semibold">
                      <span>Total</span>
                      <span>
                        {`${currencySymbol()} ${
                          Number(room?.price_per_month)?.toFixed(2) || 0
                        }`}
                        /month
                      </span>
                    </div>
                  </div>
                  <ButtonPrimary
                    disabled={
                      (selectedRoom === room.id ? isButtonDisabled : true) ||
                      room.status === "inactive"
                    }
                    onClick={formik.handleSubmit}
                    loading={isLoading}
                  >
                    Reserve
                  </ButtonPrimary>
                  <ButtonPrimary
                    onClick={() => handleContactOwner(room.id)}
                    disabled={!isAuthenticated()}
                  >
                    Contact Owner
                  </ButtonPrimary>
                  {infoModal && (
                    <CommonModal
                      isOpen={infoModal}
                      onClose={() => setInfoModal(false)}
                      description={modalDescription}
                    />
                  )}
                  {isModalOpen && (
                    <ContactOwnerModal
                      currentRoomId={currentRoomId}
                      isOpen={isModalOpen}
                      onClose={handleCloseModal}
                      onSend={handleCloseModal}
                      propertyData={propertyData}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const RenderSidebar = () => {
    dayjs.extend(isBetween);
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [error, setError] = useState("");
    const location = useLocation();

    const handleSubmit = async (values: any) => {
      const checkInDate = new Date(values.check_in_date);
      const checkOutDate = new Date(values.check_out_date);

      if (!isAuthenticated()) {
        navigate("/login", { state: { from: location.pathname } });
        return;
      }

      const calculateMonthsDifference = (
        checkInDate: string,
        checkOutDate: string
      ): number => {
        const start = moment(checkInDate, "YYYY-MM-DD");
        const end = moment(checkOutDate, "YYYY-MM-DD");
        const uniqueMonths = new Set();
        let current = start.clone();
        while (current.isBefore(end, "day") || current.isSame(end, "day")) {
          uniqueMonths.add(`${current.year()}-${current.month() + 1}`);
          current.add(1, "day");
        }

        return uniqueMonths.size;
      };

      if (!currentUser?.profile_complete) {
        setInfoModal(true);
        setModalDescription(
          "Please complete your profile before making a booking."
        );
        return;
      }
      if (checkOutDate < checkInDate) {
        return setError("check in cannot be greater than check out");
      }
      setIsLoading(true);
      try {
        const postData = {
          booking: {
            number_of_guests: Number(values.number_of_guests),
            property_id: propertyId,
            check_in_date: moment(values.check_in_date).format("YYYY-MM-DD"),
            check_out_date: moment(values.check_out_date).format("YYYY-MM-DD"),
            no_of_months: calculateMonthsDifference(
              moment(values.check_in_date).format("YYYY-MM-DD"),
              moment(values.check_out_date).format("YYYY-MM-DD")
            ),
          },
        };
        const action = await dispatch(reserveBooking(postData as any));
        if (
          action?.type === "payment/reserveBooking/fulfilled" &&
          action.payload
        ) {
          successHandler(
            "Booking request sent. Please wait for the owner's confirmation!"
          );
        }
      } catch (error) {
        ErrorHandler("Failed to reserve booking. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    const formik = useFormik({
      initialValues: {
        number_of_guests: null,
        check_in_date: null,
        check_out_date: null,
        availability_start: null,
        availability_end: null,
        unlimited_availability: false,
        minimum_months_of_booking: 0,
        room_id: null,
      },
      enableReinitialize: true,
      onSubmit: (values, { setSubmitting, resetForm }) => {
        return handleSubmit(values);
      },
    });

    useEffect(() => {
      const { check_in_date, check_out_date, number_of_guests } = formik.values;
      if (check_in_date && check_out_date && number_of_guests) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    }, [formik.values]);

    const getDisabledDates = (booked_dates: [string, string][]) => {
      const disabledDates: Date[] = [];

      booked_dates.forEach(([startDate, endDate]) => {
        if (startDate && endDate) {
          const dateRange = eachDayOfInterval({
            start: moment(startDate).toDate(),
            end: moment(endDate).toDate(),
          });
          disabledDates.push(...dateRange);
        }
      });

      return disabledDates;
    };

    if (!propertyData) return null;

    return (
      <>
        <div className="listingSectionSidebar__wrap shadow-xl">
          {/* PRICE */}
          <div className="flex justify-between">
            <span className="text-3xl font-semibold text-black dark:text-neutral-400">
              {`${currencySymbol()} ${
                Number(propertyData?.price_per_month)?.toFixed(2) || 0
              }`}
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /month
              </span>
            </span>
            <StartRating />
          </div>

          <form onSubmit={formik.handleSubmit}>
            <CustomDatePickerV2
              label="Availability Start Date"
              placeholder="Availability Start Date"
              value={formik.values.check_in_date}
              onChange={(value) => {
                formik.setFieldValue("check_in_date", value);
              }}
              minDate={moment(
                moment(propertyData?.availability_start).isAfter(moment())
                  ? propertyData?.availability_start
                  : new Date()
              ).toDate()}
              maxDate={
                propertyData?.availability_end
                  ? moment(propertyData?.availability_end).toDate()
                  : undefined
              }
              excludeDate={(date) => {
                const disabledDates = getDisabledDates(
                  propertyData.booked_dates
                );
                return disabledDates.some(
                  (disabledDate) =>
                    date.getFullYear() === disabledDate.getFullYear() &&
                    date.getMonth() === disabledDate.getMonth() &&
                    date.getDate() === disabledDate.getDate()
                );
              }}
            />

            <CustomDatePickerV2
              label="Availability End Date"
              placeholder="Availability End Date"
              value={formik.values.check_out_date}
              onChange={(value) => {
                formik.setFieldValue("check_out_date", value);
              }}
              minDate={moment(
                moment(propertyData?.availability_start).isAfter(moment())
                  ? propertyData?.availability_start
                  : new Date()
              ).toDate()}
              maxDate={
                propertyData?.availability_end
                  ? moment(propertyData?.availability_end).toDate()
                  : undefined
              }
              excludeDate={(date) => {
                const disabledDates = getDisabledDates(
                  propertyData.booked_dates
                );
                return disabledDates.some(
                  (disabledDate) =>
                    date.getFullYear() === disabledDate.getFullYear() &&
                    date.getMonth() === disabledDate.getMonth() &&
                    date.getDate() === disabledDate.getDate()
                );
              }}
            />
            <span className="text-red-500 text-xs">{error}</span>
            <FormItem
              label="Select Guest"
              className="mt-4 text-sm !text-[#18181B]"
            >
              <SelectGuests
                className={"flex-1"}
                room={{}}
                maxGuests={15}
                formik={formik}
              />
            </FormItem>
            <div className="flex flex-col space-y-4">
              <div className="flex justify-between text-neutral-6000 dark:text-neutral-300"></div>
              <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
              <div className="flex justify-between font-semibold">
                <span>Total</span>
                <span>
                  {`${currencySymbol()} ${
                    Number(
                      Number(propertyData?.price_per_month) +
                        propertyData?.property_taxes.reduce(
                          (acc, tax) => acc + tax.value,
                          0
                        )
                    )?.toFixed(2) || 0
                  }`}
                </span>
              </div>
            </div>
            <div className="w-full flex flex-col space-y-4 mt-5">
              <ButtonPrimary
                // disabled={!endDate}
                disabled={isButtonDisabled}
                onClick={() => handleSubmit(formik.values)}
                loading={isLoading}
              >
                Reserve
              </ButtonPrimary>
              <ButtonPrimary
                onClick={handleChatButtonClick}
                disabled={!isAuthenticated()}
              >
                Contact Owner
              </ButtonPrimary>
            </div>
          </form>
          {infoModal && (
            <CommonModal
              isOpen={infoModal}
              onClose={() => setInfoModal(false)}
              description={modalDescription}
            />
          )}
          {isModalOpen && (
            <ContactOwnerModal
              isOpen={isModalOpen}
              onClose={handleCloseModal}
              onSend={handleSendMessage}
              propertyData={propertyData}
            />
          )}
        </div>
      </>
    );
  };

  if (!propertyData) return null;
  return (
    <div className="nc-ListingStayDetailPage">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          {/* Main Image */}
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
            onClick={handleOpenModalImageGallery}
          >
            <NcImage
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={propertyData.place_images[0]?.url}
              alt="Property Image"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {propertyData.place_images
            .filter((_, i) => i >= 1 && i < 5)
            .map((item, index) => (
              <div
                key={item.id}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? "hidden sm:block" : ""
                } ${
                  propertyData.place_images.length <= 2 ? "-mb-1 sm:-mb-2" : ""
                }`}
              >
                <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                  <NcImage
                    className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                    src={item.url || ""}
                    alt={`Property Thumbnail ${index + 1}`}
                    sizes="400px"
                  />
                </div>

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={handleOpenModalImageGallery}
                />
              </div>
            ))}

          <button
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="w-5 h-5" />
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Show all photos
            </span>
          </button>
        </div>
      </header>
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        <div
          className={`space-y-8 lg:space-y-10 ${
            propertyData?.is_shared_property
              ? ""
              : " w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10"
          }`}
        >
          <RenderSectionDetails propertyData={propertyData} slug={slug || ""} />
          <PropertyDescription propertyData={propertyData} />
          <AmenitiesFeatures
            propertyData={propertyData}
            openModalAmenities={openModalAmenities}
            isOpenModalAmenities={isOpenModalAmenities}
            closeModalAmenities={closeModalAmenities}
          />
          <HostInformation propertyData={propertyData} />
          <ListingMap propertyData={propertyData} userLocation={userLocation} />
          <Cancellation propertyData={propertyData} />
          <RenderSection9 />
          <Review propertyData={propertyData} />
        </div>
        <div className=" lg:block flex-grow mt-14 lg:mt-0">
          {!propertyData?.is_shared_property && (
            <div className="sticky top-28">
              <RenderSidebar />
            </div>
          )}
        </div>
      </main>
    </div>
  );
};

export default function ListingStayDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
