// src/RedirectIfAuthenticated.tsx
import React from "react";
import { Navigate } from "react-router-dom";

// Define the props for the component
interface RedirectIfAuthenticatedWrapperProps {
  children: React.ReactNode;
}

// Function to check if the user is authenticated
const isAuthenticated = () => {
  // Replace this with your actual authentication logic
  return localStorage.getItem("authToken") !== null;
};

// Define the RedirectIfAuthenticatedWrapper component
const RedirectIfAuthenticatedWrapper: React.FC<RedirectIfAuthenticatedWrapperProps> = ({ children }) => {

  // Render the children if the user is not authenticated
  return <>{children}</>;
};

export default RedirectIfAuthenticatedWrapper;
