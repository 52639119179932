import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";

interface ListingMapProps {
  propertyData: PropertyToEdit | null;
  userLocation: {
    lat: number;
    lng: number;
  };
}

const ListingMap: React.FC<ListingMapProps> = ({
  propertyData,
  userLocation,
}) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY || "",
    libraries: ["places"],
  });
  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return (
      <div className="flex items-center justify-center py-10">
        <div className="flex items-center justify-center space-x-2">
          <div className="w-10 h-10 border-4 border-t-4 border-gray-300 border-t-primary-6000 rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }

  const mapContainerStyle = {
    width: "100%",
    height: "100%",
  };

  return (
    <div
      className={`listingSection__wrap ${
        propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
      }`}
    >
      <div>
        <h2 className="text-2xl font-semibold">Location</h2>
        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
          {propertyData?.address ? propertyData?.address : "Not available"}
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
      <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={10}
          center={userLocation}
        >
          <Marker position={userLocation} />
        </GoogleMap>
      </div>
    </div>
  );
};

export default ListingMap;
