import axios from "axios";
import ErrorHandler from "./ErrorHandler";
import { store } from "../../redux/store";
import { logout } from "../../redux/reducers/AuthSlice/AuthSlice";

const API = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("authToken"); // Fetch token from localStorage
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`; // Set the Authorization header
    }
    if (config.data instanceof FormData) {
      config.headers["Content-Type"] = "multipart/form-data"; // Set the content type for file uploads
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor to handle errors globally
API.interceptors.response.use(
  (response) => response.data, // Return response data directly
  (error) => {
    const statusCode = error.response?.status;
    if (statusCode === 401 || statusCode === 403) {
      // Handle unauthorized or forbidden (e.g., invalid or expired token)
      console.log("Session expired. Please login again.");
      ErrorHandler("Unauthorized Error!");

      const preserveKey = "theme"; // The key you want to preserve
      const preserveValue = localStorage.getItem(preserveKey); // Store the value of the key
      if (preserveValue) {
        localStorage.setItem(preserveKey, preserveValue);
      }

      setTimeout(() => {
        import("../../redux/store").then(({ store }) => {
          store.dispatch(logout()); // Dynamically import store
          window.location.href = "/login";
        });
      }, 2000);
    }
    return Promise.reject(error);
  }
);

export default API;
