import React, { FC, ReactNode, useEffect, useState } from "react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import StayCard from "components/StayCard/StayCard";
import { AppDispatch, RootState } from "redux/store";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getAllProperties } from "../../redux/reducers/Properties/PropertiesSlice";
import SectionGridFeaturePlacesSkeleton from "shared/Skelton/SectionGirdSkelton";

const DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i);

export interface SectionGridFeaturePlacesProps {
  stayListings?: StayDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
  setAllData?: React.Dispatch<React.SetStateAction<StayDataType[]>>;
  pagemetaData?: any;
}
const SectionGridFeaturePlaces: FC<SectionGridFeaturePlacesProps> = ({
  stayListings = DATA,
  setAllData,
  pagemetaData,
  gridClass = "",
  heading = "Featured places to stay",
  subHeading = "Popular places to stay that Chisfis recommends for you",
  headingIsCenter,
  tabs = ["New York", "Tokyo", "Paris", "London"],
}) => {
  const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);
  const [filteredData, setFilteredData] = useState<StayDataType[]>(stayListings);
  const dispatch = useDispatch<AppDispatch>();
  const [page, setPage] = useState(1);
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingPrev, setLoadingPrev] = useState(false);

  const { loading, allProperties, metaData } = useSelector(
    (state: RootState) => state.property
  );

  const renderCard = (stay: StayDataType) => {
    return (
      <StayCard
        key={stay.id}
        data={stay}
        setAllData={setAllData}
        stayListings={filteredData}
      />
    );
  };
  const handleLoadMore = async () => {
    if (metaData?.next_page) {
      setLoadingNext(true);
      await dispatch(getAllProperties(page + 1));
      setPage((prevPage) => prevPage + 1);
      setLoadingNext(false);
    }
  };

  const handleGoBack = async () => {
    if (metaData?.prev_page && page > 1) {
      setLoadingPrev(true);
      await dispatch(getAllProperties(page - 1));
      setPage((prevPage) => prevPage - 1);
      setLoadingPrev(false);
    }
  };
  useEffect(() => {
    dispatch(getAllProperties(page));
  }, [dispatch, page]);

  return (
    <div className="nc-SectionGridFeaturePlaces relative">
      {loading ? (
        <SectionGridFeaturePlacesSkeleton />
      ) : (
        <>
          <HeaderFilter
            tabActive={"New York"}
            subHeading={subHeading}
            tabs={tabs}
            heading={heading}
            onClickTab={() => {}}
          />
          <div
            className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 ${gridClass}`}
          >
            {stayListings?.map((stay) => renderCard(stay))}
          </div>
          <div className="flex mt-16 justify-center items-center space-x-4">
            {metaData?.prev_page && (
              <ButtonPrimary
                loading={loadingPrev}
                onClick={handleGoBack}
                disabled={loadingNext}
              >
                Go Back
              </ButtonPrimary>
            )}
            {metaData?.next_page && (
              <ButtonPrimary
                loading={loadingNext}
                onClick={handleLoadMore}
                disabled={loadingPrev}
              >
                Show me more
              </ButtonPrimary>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default SectionGridFeaturePlaces;
