import React from "react";
import { PropertyToEdit } from "redux/reducers/Properties/PropertiesSliceTypes";

interface CancellationProps {
  propertyData: PropertyToEdit | null;
}

const Cancellation: React.FC<CancellationProps> = ({ propertyData }) => {
  return (
    <div
      className={`listingSection__wrap ${
        propertyData?.is_shared_property ? "lg:w-3/4" : "w-full"
      }`}
    >
      <h2 className="text-2xl font-semibold">Things to know</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
      <div>
        <h4 className="text-lg font-semibold">Cancellation policy</h4>
        <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
          {propertyData?.cancellation_policy?.description}
        </span>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
      <div>
        <h4 className="text-lg font-semibold">Check-in time</h4>
        <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
          <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
            <span>Check-in</span>
            <span>08:00 am - 12:00 am</span>
          </div>
          <div className="flex space-x-10 justify-between p-3">
            <span>Check-out</span>
            <span>02:00 pm - 04:00 pm</span>
          </div>
        </div>
      </div>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />
      <div>
        <h4 className="text-lg font-semibold">Special Note</h4>
        <div className="prose sm:prose">
          <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
            <li>
              Ban and I will work together to keep the landscape and environment
              green and clean by not littering, not using stimulants and
              respecting people around.
            </li>
            <li>Do not sing karaoke past 11:30</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Cancellation;
