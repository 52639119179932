import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "redux/store";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import {
  bookingQuery,
  getBookingsQuery,
} from "../../../redux/reducers/Properties/PropertiesSlice";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionGridFeaturePlacesV2 from "containers/PageHome/SectionGridFeaturePlacesV2";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionGridFeaturePlacesSkeleton from "shared/Skelton/SectionGirdSkelton";

const BookingQuery: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { currentUser } = useSelector((state: RootState) => state.user);
  const [allData, setAllData] = useState<any>([]);
  const [pagemetaData, setMetaData] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);

  const getRandomIndex = () => Math.floor(Math.random() * 8);

  const mergedData = (dataToBeMerged: any[]) => {
    const newData = dataToBeMerged?.map((apiItem) => {
      const randomIndex = getRandomIndex();
      const fallback = DEMO_DATA[randomIndex];
      return {
        ...fallback,
        id: apiItem?.id,
        title: apiItem?.property?.title || fallback?.title,
        place_images: apiItem?.property?.place_images || fallback?.galleryImgs,
        address: apiItem?.property?.address,
        availability_start: apiItem.availability_start || fallback?.date,
        availability_end: apiItem.availability_end || fallback?.date,
        price: apiItem?.property?.price_per_month,
        href: `/booking_queries_details?id=${apiItem?.id}`,
        state: apiItem?.state,
        check_in_date: apiItem?.check_in_date,
        check_out_date: apiItem?.check_out_date,
        guest_count: apiItem?.guest_count,
      };
    });
    setAllData(newData);
  };

  const fetchData = async (page: number) => {
    setIsLoading(true);

    try {
      if (currentUser?.is_host) {
        const res = await dispatch(getBookingsQuery(page)).unwrap();
        mergedData(res?.booking_queries);
        setMetaData(res.meta);
      } else if (!currentUser?.is_host) {
        const res = await dispatch(bookingQuery(page)).unwrap();
        mergedData(res?.booking_queries);
        setMetaData(res.meta);
      }
    } catch (error: any) {
      console.error("Error fetching properties:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchData(currentPage);
    }
  }, [currentPage, currentUser]);

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= pagemetaData?.total_pages) {
      setCurrentPage(newPage);
    }
  };

  if (isLoading) {
    return (
      <div className="p-10">
        <SectionGridFeaturePlacesSkeleton />;
      </div>
    );
  }
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        <div className="relative py-16">
          <BackgroundSection />
          <SectionGridFeaturePlacesV2 stayListings={allData} />
        </div>
        {pagemetaData?.total_pages > 1 && (
          <div className="flex mt-16 justify-center items-center space-x-4">
            <ButtonPrimary
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </ButtonPrimary>
            <ButtonPrimary
              disabled={currentPage === pagemetaData?.total_pages}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </ButtonPrimary>
          </div>
        )}
      </div>
    </div>
  );
};

export default BookingQuery;
